import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Sidebar } from '../components/Sidebar';
import { chapterNumbers, chapterTitles } from '../utils/chapter';
import Section from '../components/Section';
import ContentContainer from '../components/ContentContainer';
import ArrowRightSvg from '../svgs/arrow-right.svg';

class BlogPostTemplate extends React.Component {
  render() {
    const sections = this.props.data.allMdx.sections;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const chapter = this.props.pageContext.chapter;
    const nextChapterNumber = chapterNumbers[chapter] + 1;
    const nextChapterTitle = chapterTitles[nextChapterNumber];

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={chapter} description={chapterTitles[chapter]} />
        <ContentContainer className="flex">
          <Sidebar chapter={chapter} />
          <div className="list-none flex-1 flex-shrink min-w-0">
            {sections.map(
              section =>
                !section.frontmatter.hidden && (
                  <div key={section.frontmatter.title}>
                    <Section section={section} />
                  </div>
                )
            )}
            {nextChapterTitle && (
              <Link
                className="mx-auto mt-16 inline-flex mb-16 items-center rounded-full px-6 py-2 shadow-none border-none appearance-none font-bold cursor-pointer hover:bg-gray-700 bg-gray-800 text-white text-lg"
                to={`/chapter-${nextChapterNumber}`}
              >
                <ArrowRightSvg className="m-0 mr-2" aria-hidden />
                Next Chapter ({nextChapterTitle})
              </Link>
            )}
          </div>
        </ContentContainer>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query($chapter: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMdx(
      filter: { frontmatter: { chapter: { eq: $chapter } } }
      sort: { fields: frontmatter___section }
    ) {
      sections: nodes {
        id
        frontmatter {
          title
          section
          incomplete
          hidden
        }
        body
      }
    }
  }
`;
